import userImage_1 from "./images/userImages/userImage_1.png";
import userImage_2 from './images/userImages/userImage_2.png';
import userImage_3 from './images/userImages/userImage_3.png';

export const StruggleData = [
  { name: "Struggling", value: 64 },
  { name: "Not Struggling", value: 36 },
];

export const ActivationData = [
  { name: "Active", value: 29 },
  { name: "Not Active", value: 71 },
];

export const FeelBetterData = [
  { name: "Feel Better", value: 100 },
  { name: "Not Feel Better", value: 0 },
];

export const ExperienceData = [
  { name: "Experience", value: 91 },
  { name: "Not Experience", value: 9 },
];

export const CommentsData = [
  {
    userName: "James Harrington",
    userImage: userImage_1,
    rating: 4,
    comment: `It was great! Came in here pretty stressed... and this just loosened everything up- it's good to take that time.`,
  },
  {
    userName: "Sarah Whitman",
    userImage: userImage_2,
    rating: 4,
    comment: `This was my first time with mindfulness. It was very enlightening! This is phenomenal, thank you so much for this experience!`,
  },
  {
    userName: "Rachel Evans",
    userImage: userImage_3,
    rating: 5,
    comment: `Class was great, now I feel more balanced, more zoned in and I feel more mental clarity.`,
  },
];
